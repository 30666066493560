html {
    font-family: 'Montserrat', sans-serif;
    background: #090a13;
}
html, body, #root, .App {
    height: 100%;
}
.faq-contents-list  a{
    color: white;
    text-decoration: none;
}
.faq-contents-list  a:hover{
    color: rgba(255, 255, 255, 0.69);
    text-decoration: none;
}
.faq-contents-list li {
    margin: 10px;
}
.faq-item a {
    color: #0dbcfe;
    text-decoration: none;
    margin: 10px;
}
.rules-contents-list a{
    color: white;
    text-decoration: none;
}

.container li::marker {
    color: #0dbcfe;
}
.container a {
    color: #0dbcfe;
    text-decoration: none;
    margin: 10px;
}